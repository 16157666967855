.footer{
    display: flex;
    justify-content: end;
    padding: 7.5px 28px;
}

.text{
    margin: 0;
    font-size: 12px;
    line-height: 14.52px;
    font-weight: 300;
    opacity: 0.8;
}