.containerWarning{
	display: flex;
	height: calc(100vh - 140px);
	justify-content: center;
}


.container{
	max-width: 357px;
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	gap: 20px;
	align-items: center;
}

.title{
	color: #1E1E1ECC;
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
	margin: 0;
}

.text{
	color: #1E1E1E;
	font-size: 12px;
	font-weight: 400;
	line-height: 14.52px;
	margin: 0;
}

.img{
	max-width: 225px;
	max-height: 225px;
	width: 100%;
	height: 100%;
}