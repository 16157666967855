.button{
    background-color: #76CEC9;
    max-width: 150px;
    width: 100%;
    height: 36px;
    border: none;
    color: white;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    gap: 15px;
    margin-top: 15px;
}

.icon {
	font-size: 20px;
	color: #8090B8;
    cursor: pointer;
}

.icon:nth-child(n) {
	padding-inline: 3px;
}

.icon:hover {
	color: #327FEF;
}

@media screen and (max-width: 640px){
    .button{
        max-width: 48px;
        margin-left: 33px;
    }
}