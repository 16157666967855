.container{
    border: 1px solid #D5D5D5;
    max-width: 450px;
    width: 100%;
}

.containerTitle{
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 12px 22px 15px 22px;
    border-bottom: 1px solid #D5D5D5;
}

.title{
    color: #1E1E1E;
    font-size: 14px;
    line-height: 16.94px;
    margin: 0;
}

.containerButton{
    display: flex;
    flex-direction: column;
    padding: 19px 22px 22px 22px;
}

.text_info{
    color: #8B8B8B;
    font-size: 12px;
    line-height: 14.52px;
    margin: 0;
}

.button{
    background-color: #76CEC9;
    height: 36px;
    border: none;
    color: white;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    align-items: center;
    justify-content: start;
}