.button{
    background-color: #76CEC9;
    width: 173px;
    height: 36px;
    border: none;
    color: white;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    gap: 15px
}

.button_text{
    font-size: 12px;
    font-weight: 400;
}

@media screen and (max-width: 640px){
    .button{
        max-width: 48px;
        margin-left: 33px;
    }
}