.header{
    display: flex;
    align-items: center;
    padding: 10px 22px 0 0;
    gap: 31px;
}

.headerBlock{
    display: flex;
    align-items: center;
    gap: 31px;
    width: 100%;
}

.containerHeader{
    background-color: white;
    width: 100%;
    display: flex;
    padding: 12px 18px 12px 7px;
    border-radius: 2px;
    border: 1px solid #D5D5D5;
    justify-content: space-between;
    height: 44px;
    box-sizing: border-box;
    position: relative;
}

.container{
    display: flex;
    align-items: center;
    gap: 12px;
}

.buttonSidebar{
    width: 26px;
    height: 14px;
    cursor: pointer;
    background-color: white;
    padding: 0;
    border: none;
}

.buttonSidebar:hover{
    background-color: transparent;
}

.text{
    font-size: 15px;
    font-weight: 400;
    line-height: 14.52px;
    margin: 0;
}

.buttonHeader{
    background-color: white;
    display: flex;
    border: none;
    padding: 0;
    align-items: center;
    gap: 9px;
    cursor: pointer;
    height: auto;
    box-shadow: none;
}

.textUser{
    opacity: 0.5;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
}

.arrow{
    opacity: 0.5;
}

.button{
    background-color: #76CEC9;
    height: 36px;
    border: none;
    color: white;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    align-items: center;
    justify-content: start;
}

.button_text{
    font-size: 12px;
    font-weight: 400;
}

.iconHelp{
    width: 25px;
    height: 25px;
    cursor: pointer;
}

@media screen and (max-width: 640px){
    .header{
        gap: 7px;
        padding: 6px 10px 0 0;
    }
    .button{
        max-width: 48px;
        margin-left: 33px;
    }
    .container{
        gap: 7px;
    }
    .text{
        font-size: 10px;
    }
    .containerHeader{
        padding: 10px 10px 10px 7px;
    }
}