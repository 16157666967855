.menu{
    position: absolute;
    background-color: white;
    top: 35px;
    left: 50px;
    width: 300px;
    padding: 5px;    
    box-shadow: 0px 2px 3px 0px #0000001A;
    z-index: 10;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.itemMenu{
    padding: 7px 15px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
}

.itemMenu:hover{
    background-color: #76CEC9;
    border-radius: 2px;
}

.horizontalLine {
    border: none;
    height: 1px;
    background-color: #ccc;
    margin: 10px 0;
}

.background{
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}