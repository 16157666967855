.text{
    color: #8B8B8B;
    font-size: 12px;
    line-height: 14.52px;
    margin: 0 0 13px;
}
.table  {
	border: 1px solid #d9d9d9;
	border-top: none;
}
.table :global(.ant-table-cell)::before {
	width: 0 !important;
	line-height: 24px;
}
.table :global(.ant-table-cell) {
	padding: 12px !important;
	font-size: 12px;
	font-style: normal;
	vertical-align: middle;
	color: #000000 !important;
	line-height: 14.52px;
    background-color: white;
}

.table th:global(.ant-table-cell) {
	font-size: 10px;
	text-transform: uppercase;
	color: #000000 !important;
	background-color: #FCFDFF !important;
    font-weight: 400;
}

/* .table :global(.ant-table-row) {
	cursor: pointer;
} */

.table :global(.ant-table-row):nth-child(2n) {
	background-color: #FFFFFF !important;
}

.table :global(.ant-table-row):nth-child(2n - 1) {
	background-color: #FCFDFF !important;
}

.table_header thead th {
	position: sticky;
	top: 0;
    border-top: 1px solid #d9d9d9 !important;
    border-radius: 0 !important;
}

.icon {
	font-size: 20px;
	color: #8090B8;
}

.icon:nth-child(n) {
	padding-inline: 3px;
}

.icon:hover {
	color: #327FEF;
}