.button{
    background-color: #76CEC9;
    max-width: 173px;
    width: 100%;
    height: 36px;
    border: none;
    color: white;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    gap: 15px
}

.button_text{
    font-size: 12px;
    font-weight: 400;
}

.switch {
	margin: 0;
}

.switch :global(.ant-switch-checked) {
	background: #00ff00;
}

.switch :global(.ant-switch-checked:hover) {
    background: #009900 !important;;
}

.text{
    color: #000000;
    font-size: 12px;
    line-height: 14.52px;
    margin-left: 12px
}

.colContainer{
    background-color: #FFFFFF;
    border: 1px solid #D5D4D4;
    padding: 12px 15px;
    max-width: 450px;
    gap: 12px;
    display: flex;
    flex-direction: column;
    height: 145px;
}

.spinContainer{
    padding: 12px 15px;
    max-width: 450px;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 640px){
    .button{
        max-width: 48px;
        margin-left: 33px;
    }
}