.login{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.containerInputs{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 34px;
    max-width: 356px;
    width: 100%;
    margin-bottom: 41px;
}

.text{
    margin: 0;
    font-size: 20px;
    line-height: 24.2px;
    font-weight: 800;
    color: #1E1E1ECC;
}

.containerInput{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.label{
    color: #1E1E1E80;
    font-size: 10px;
    line-height: 12.1px;
    font-weight: 400;
}

.input{
    border: 1px solid #4B4A4A33;
    border-radius: 5px;
    height: 41.59px;
    outline: none;
}

.reset{
    margin: 0;
    color: #3D6992;
    font-size: 13px;
    font-weight: 700;
    line-height: 15.73px;
    cursor: pointer;
}

.button{
    background-color: #76CEC9;
    color: white;
    width: 100%;
    border: none;
    border-radius: 5px;
    max-width: 356px;
    height: 44px;
    cursor: pointer;
}
