.containerLogo{
    width: 100%;
    height: 44px;
    border-radius: 2px;
    background-color: #76CEC9;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.textLogo{
    color: white;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.container{
    display: flex;
    justify-content: space-between;
    border: 1px solid #76CEC9;
    border-left: none;
    border-radius: 2px;
    background-color: white;
    height: 44px;
    padding: 10px 10px 10px 0;
    gap: 5px;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
}

.containerStudio{
    display: flex;
    align-items: center;
    gap: 9px;
}

.containerInfo{
    display: flex;
    flex-direction: column;
}

.nameStudio{
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}

.roleUser{
    margin: 0;
    font-size: 8px;
    font-weight: 300;
    line-height: 9.68px;
}

.arrow{
    width: 13px;
    height: 7px;
}

.arrowItem{
    width: 7px;
    height: 3px;
}

.containerNavigates{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.containerItem{
    display: flex;
    padding: 5px 6px;
    justify-content: space-between;
    height: 34px;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
}

.containerItem:hover{
    box-shadow: 2px 2px 5px 0px #0000001A;
    border-radius: 2px;
}

.containerLink{
    display: flex;
    gap: 9px;
}

.linkName{
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
}

.padding0{
 padding: 0;
}
  
.customSubmenuPopup {
    background-color: transparent;
}
  
.customMenuItem {
    background-color: transparent;
    transition: color 0.3s;
    color: #323232;
    margin: 0;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
}

.customMenuItem:hover {
    background-color: #FFF;
}

.subMenuItemAcive {
    display: flex !important;
}
.subMenuItemContainer{
    left: 100%;
    top: 0;
    padding: 14px 23px 18px;
    background-color: #FFFFFF;
    border-radius: 2px;
    z-index: 10;
    position: absolute;
    min-height: 200px;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.subMenuItem {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.customLink {
    color: #8B8B8B;
    text-decoration: none;
    transition: color 0.3s ease;
    width: 100%;
    display: block;
}

.customLinkSelected {
    color: #323232;
    text-decoration: none;
    transition: color 0.3s ease;
}
  
.customLink:hover {
    color: #323232;
}