.container{
    border: 1px solid #D5D5D5;
    border-radius: 5px;
    background-color: white;
    padding: 10px 10px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.containerInputs{
    display: flex;
    gap: 27px;
}

.containerInput{
    display: flex;
    flex-direction: column;
    gap: 7px;
    max-width: 453px;
    width: 100%;
}

.input{
    height: 28px;
    border-radius: 5px;
    border: 1px solid #4B4A4A33;
    outline: none;
}

.containerSubmit{
    display: flex; 
    justify-content: space-between;
    align-items: center;
}

.containerCount{
    display: flex;
    gap: 10px;
    align-items: center;
}

.textCount{
    margin: 0;
    font-size: 12;
    font-weight: 300;
    line-height: 14.52px;
}

.count{
    border: 1px solid #4B4A4A33;
    border-radius: 2px;
    width: 53px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 300;
    line-height: 14.52px;
    margin: 0;
}

.button{
    background-color: #3D6992;
    border-radius: 5px;
    height: 30px;
    max-width: 150px;
    width: 100%;
    color: white;
    border: none;
    cursor: pointer;
}
.table  {
	border: 1px solid #d9d9d9;
    border-top: none;
}
.table :global(.ant-table-cell)::before {
	width: 0 !important;
	line-height: 24px;
}
.table :global(.ant-table-cell) {
	padding: 12px !important;
	font-size: 12px;
	font-style: normal;
	vertical-align: middle;
	color: #000000 !important;
	line-height: 14.52px;
    background-color: white;
}

.table th:global(.ant-table-cell) {
	font-size: 10px;
	text-transform: uppercase;
	color: #000000 !important;
	background-color: #FCFDFF !important;
    font-weight: 400;
}

.table :global(.ant-table-row) {
	cursor: pointer;
}

.table :global(.ant-table-row):nth-child(2n) {
	background-color: #FFFFFF !important;
}

.table :global(.ant-table-row):nth-child(2n - 1) {
	background-color: #FCFDFF !important;
}

.table_header thead th {
	position: sticky;
	top: 0;
    border-top: 1px solid #d9d9d9 !important;
    border-radius: 0 !important;
}

.icon {
	font-size: 20px;
	color: #8090B8;
}

.icon:nth-child(n) {
	padding-inline: 3px;
}

.icon:hover {
	color: #327FEF;
}

.commentText{
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rowTable td:global(.ant-table-cell) {
	background-color: #DAF7FA !important;
}