.table  {
	border: 1px solid #d9d9d9;
    border-top: none;
    border-bottom: none;
}
.table :global(.ant-table-cell)::before {
	width: 0 !important;
	line-height: 24px;
}
.table :global(.ant-table-cell) {
	padding: 12px !important;
	font-size: 12px;
	font-style: normal;
	vertical-align: middle;
	color: #000000 !important;
	line-height: 14.52px;
    background-color: white;
}

.table th:global(.ant-table-cell) {
	font-size: 10px;
	text-transform: uppercase;
	color: #000000 !important;
	background-color: #FCFDFF !important;
    font-weight: 400;
}

.table th:global(.ant-table-cell):first-of-type {
    padding: 0 !important;
}

.table td:global(.ant-table-cell):first-of-type {
    padding: 0 !important;
}

.table :global(.ant-table-row) {
	cursor: pointer;
}

.table :global(.ant-table-row):nth-child(2n) {
	background-color: #FFFFFF !important;
}

.table :global(.ant-table-row):nth-child(2n - 1) {
	background-color: #FCFDFF !important;
}

.table_header thead th {
	position: sticky;
	top: 0;
    border-top: 1px solid #d9d9d9 !important;
    border-radius: 0 !important;
}

.table :global(.ant-table-footer) {
	/* padding: 12px !important; */
	color: #000000 !important;
	line-height: 14.52px;
    background-color: white;
    position: sticky;
	bottom: 0;
    border-bottom: 1px solid #d9d9d9 !important;
    border-top: 1px solid #d9d9d9 !important;
    border-radius: 0;
    display: flex;
    justify-content: end;
}

.icon {
	font-size: 20px;
	color: #8090B8;
}

.icon:nth-child(n) {
	padding-inline: 3px;
}

.icon:hover {
	color: #327FEF;
}

.button{
    background-color: #76CEC9;
    width: 173px;
    height: 36px;
    border: none;
    color: white;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    gap: 15px;
    margin-top: 30px;
}

.button_close{
	background-color: #CA4F4F;
	width: 173px;
    height: 36px;
    border: none;
    color: white;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    gap: 15px;
    margin-top: 30px;
}

.button_text{
    font-size: 12px;
    font-weight: 400;
}

@media screen and (max-width: 1250px){
    .button{
        margin-top: 0;
    }
    .button_close{
        margin-top: 0;
    }
}

