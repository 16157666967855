html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Inter'
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9F9F9;
  color: #1E1E1E;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:where(.css-dev-only-do-not-override-m4timi).ant-form-item .ant-form-item-label >label{
  color: #1E1E1E80;
}

:where(.css-dev-only-do-not-override-i729ar).ant-menu-light.ant-menu-root.ant-menu-inline, :where(.css-dev-only-do-not-override-i729ar).ant-menu-light>.ant-menu.ant-menu-root.ant-menu-inline, :where(.css-dev-only-do-not-override-i729ar).ant-menu-light.ant-menu-root.ant-menu-vertical, :where(.css-dev-only-do-not-override-i729ar).ant-menu-light>.ant-menu.ant-menu-root.ant-menu-vertical{
  background-color: #F9F9F9;
  border: none;
}

:where(.css-dev-only-do-not-override-m4timi).ant-layout .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed{
  background-color: #F9F9F9;
  border: none;
}

:where(.css-dev-only-do-not-override-i729ar).ant-menu .ant-menu-item, :where(.css-dev-only-do-not-override-i729ar).ant-menu .ant-menu-submenu, :where(.css-dev-only-do-not-override-i729ar).ant-menu .ant-menu-submenu-title{
  border-radius: 2px;
  margin: 0 4px;
}

.react-tel-input .form-control{
  border-radius: 2px !important;
}

.ant-select-selector{
  border-radius: 2px  !important;
}

:where(.css-dev-only-do-not-override-m4timi).ant-select-multiple .ant-select-selector{
  border-radius: 2px  !important;
}

:where(.css-dev-only-do-not-override-m4timi).ant-select-dropdown{
  border-radius: 2px !important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #76CEC9;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #66B4B7;
}

.ant-menu-item-group-title{
  padding: 0;
}
:where(.css-dev-only-do-not-override-m4timi).ant-table-wrapper table{
  border-radius: 2px;
}

:where(.css-dev-only-do-not-override-12nueui).ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title{
  color: rgba(0, 0, 0, 0.88);
}
:where(.css-dev-only-do-not-override-12nueui).ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover, :where(.css-dev-only-do-not-override-12nueui).ant-menu-light>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover{
  color: rgba(0, 0, 0, 0.88);
  background-color: #fff;
}

:where(.css-dev-only-do-not-override-12nueui).ant-menu .ant-menu-item-group .ant-menu-item-group-list .ant-menu-item{
  color: #8B8B8B;
}
:where(.css-dev-only-do-not-override-m4timi).ant-menu-inline .ant-menu-item{
  border-radius: 0;
  margin: 0 0 0 8px;
}
:where(.css-dev-only-do-not-override-m4timi).ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover{
  background-color: #fff;
}
.ant-menu-submenu.ant-menu-submenu-inline > .ant-menu-submenu-title {
  border-radius: 2px;
  margin: 0 0 0 8px;
}
:where(.css-dev-only-do-not-override-m4timi).ant-table-wrapper .ant-table-thead >tr>th{
  border-bottom: 1px solid #d5d5d5;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open > .ant-menu-submenu-title {
  background-color: #5DCBC499;
  border-radius: 2px;
  margin: 0 0 0 8px;
}

.ant-table-thead{
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

:where(.css-dev-only-do-not-override-m4timi).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
  width: 193px !important;
  height: 193px !important;
}
:where(.css-m4timi).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select{
  width: 100%;
  height: 100%;
}

:where(.css-dev-only-do-not-override-m4timi).ant-layout .ant-layout-sider-children{
  margin: 0;
}


@media screen and (max-width: 640px){
  .ant-picker-datetime-panel{
      display: block !important;
  }
}
