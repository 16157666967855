.container{
    border: 1px solid #D5D5D5;
    max-width: 666px;
    width: 100%;
    padding: 17px 22px 26px;
}

.containerTitle{
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 27px;
}

.title{
    color: #1E1E1E;
    font-size: 14px;
    line-height: 16.94px;
    margin: 0;
}

.containerButton{
    display: flex;
    flex-direction: column;
    gap: 9px;
}

.text{
    color: #8B8B8B;
    font-size: 12px;
    line-height: 14.52px;
    margin: 0;
    cursor: pointer;
}

.text:hover{
    color: #1E1E1E;
}