.text{
    color: #000000;
    font-size: 12px;
    line-height: 14.52px;
    margin-left: 12px
}

.switch :global(.ant-switch-checked) {
	background: #00ff00;
}

.switch :global(.ant-switch-checked:hover) {
    background: #009900 !important;;
}