.menu{
    position: absolute;
    background-color: white;
    top: 47px;
    right: 0;
    width: 230px;
    padding: 5px;    
    border: 1px solid #D5D5D5;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.itemMenu{
    padding: 7px 15px;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #1E1E1E;
    font-size: 13px;
    font-weight: 400;
    line-height: 14.52px;
}

.background{
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.itemMenu:hover{
    background-color: #76CEC9;
    border-radius: 2px;
}

.horizontalLine {
    border: none;
    height: 1px;
    background-color: #ccc;
    margin: 10px 0;
  }